<template>
    <v-container
        v-if="productionOrder"
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold tw-ml-5 lg:tw-ml-6">{{ productionOrder.OrdemFabrico }}</h1>
            </v-col>
            <v-col
                v-if="operation"
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-xl">{{ operation }}</h1>
            </v-col>
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <p class="tw-font-semibold">
                    {{ productionOrder.Descricao }}
                </p>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn

                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="x-large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
            <!-- <v-col
                v-if="isLoading"
                cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col> -->
        </v-row>
        <router-view
            v-if="productionOrder"
            :productionOrder="productionOrder"
            :workCenters="workCenters"
            :hoursTypes="hoursTypes"
            :timeUnits="timeUnits" />
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';

    import { getProductionOrder } from '@/api/productionOrders';
    import { getWorkCenters } from '@/api/workCenters';
    import { getHoursTypes } from '@/api/hoursTypes';
    import { getTimeUnits } from '@/api/timeUnits';

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $alert = useAlert();

    const navItems = ref([{ title: 'Detalhes', to: { name: 'ProductionOrderDetails' } }]);

    const productionOrder = ref<any>(null);
    const workCenters = ref<any>(null);
    const hoursTypes = ref<any>(null);
    const timeUnits = ref<any>(null);

    async function init() {
        showLoader();
        try {
            const productionOrderId = $route.params.id;
            const [productionOrderResponse, workCentersResponse, hoursTypesResponse, timeUnitsResponse] = await Promise.all([
                getProductionOrder(productionOrderId as string),
                getWorkCenters(null, null, null, null),
                getHoursTypes(),
                getTimeUnits(),
            ]);
            productionOrder.value = productionOrderResponse.data;
            workCenters.value = workCentersResponse.data;
            hoursTypes.value = hoursTypesResponse.data;
            timeUnits.value = timeUnitsResponse.data;
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Ocorreu um erro ao carregar os dados da ordem de fabrico.',
            });
        } finally {
            hideLoader();
        }
    }

    init();
</script>

<style scoped></style>
