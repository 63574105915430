import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/daily-works';

export async function getDailyWorks(): AxiosPromise<any> {
    return axios.get(`${API_REF}`);
}

export async function getDailyWork(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

export async function createDailyWork(data: any): AxiosPromise<any> {
    return axios.post(`${API_REF}`, data);
}
