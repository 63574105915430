<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-5 tw-text-2xl tw-font-bold lg:tw-ml-6">{{ $t('techincalSheets.listTechnicalSheets') }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                :items="technicalSheets"
                :headers="headers"
                :items-length="total"
                @click:row="onRowClick"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                >
                <template v-slot:item.sent="{ item }">
                    <v-chip
                        size="default"
                        :color="item.sent ? 'success' : 'error'"
                        dark>
                        {{ item.sent ? 'Sim' : 'Não' }}
                    </v-chip>
                </template>
                <template v-slot:item.createdAt="{ item }">
                    <span>{{ formatDate(item.createdAt) }}</span>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { parse } from 'csv-parse/browser/esm/sync';
    import * as XLSX from 'xlsx';
    import { ref, computed, watch } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useLoader } from '@/composables/useLoader';
    import { useRouter } from 'vue-router';
    import { getTechnicalSheets } from '@/api/technicalSheets';

    const { showLoader, hideLoader } = useLoader();
    const $router = useRouter();

    const page = ref<number>(1);
    const pageSize = ref<number>(10);
    const total = ref<number>(0);
    const headers = ref<any[]>([
        {
            title: 'ID',
            key: 'id',
        },
        {
            title: 'Artigo',
            key: 'article',
        },
        {
            title: 'Enviado para Primavera?',
            key: 'sent',
        },
        {
            title: 'Data de Criação',
            key: 'createdAt',
        },
    ]);

    const technicalSheets = ref<any[]>([]);

    const init = async () => {
        try {
            showLoader();
            let params = {};

            if (pageSize.value > 0) {
                params = {
                    pagination: {
                        page: page.value,
                        pageSize: pageSize.value,
                    },
                };
            }

            const response = await getTechnicalSheets(params);

            technicalSheets.value = response.data.data;
            total.value = response.data.meta.pagination.total;
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    const onRowClick = (row: any, e: any) => {
        console.log(row);
        console.log(e);

        $router.push({ name: 'TechnicalSheetDetail', params: { id: e.item.id } });
    };

    init();

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };

    watch([page, pageSize], () => {
        console.log('page or pageSize changed');
        console.log(page.value, pageSize.value);
        init();
    });
</script>

<style scoped></style>
