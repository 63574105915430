<template>
    <Sidebar />
    <Navbar />
    <v-main class="tw-ml-8">
        <ioAlert />
        <v-icon @click="() => $router.go(-1)" class="!tw-absolute tw-py-8 tw-text-primary lg:tw-px-8 lg:tw-py-16">mdi-arrow-left</v-icon>
        <!-- <v-btn @click="isSidebarOpen = !isSidebarOpen" class="tw-z-10" position="fixed" :location="'left top'" icon="mdi-menu" ></v-btn> -->
        <router-view />
    </v-main>
</template>

<script lang="ts" setup>
    import Sidebar from './Sidebar.vue';
    import Navbar from './Navbar.vue';
    import ioAlert from '@/components/ioAlert.vue';
    import { useRouter } from 'vue-router';

    const $router = useRouter();
</script>
