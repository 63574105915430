<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-5 tw-text-2xl tw-font-bold lg:tw-ml-6">{{ $t('internalDocuments.OSI.title') }}</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    color="primary"
                    @click="$router.push({ name: 'CreateInternalOrderService' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    placeholder="Pesquisar"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-data-table
                :hover="true"
                :items="documents"
                @click:row="goToInternalOrderService">
                <template v-slot:item.Data="{ item }">
                    <span>{{ formatDate(item.Data) }}</span>
                </template>
            </v-data-table>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { getInternalDocumentsByDocType } from '@/api/internalDocuments';
    import { useAlert } from '@/composables/useAlert';

    const route = useRoute();
    const router = useRouter();
    const $alert = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const documents = ref([]) as any;

    const init = async () => {
        showLoader();
        try {
            const internalDocuments = await getInternalDocumentsByDocType('OSI');
            console.log(internalDocuments);
            documents.value = internalDocuments.data;
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar documentos internos',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    init();

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };

    const goToInternalOrderService = (event: any, row: any) => {
        router.push({ name: 'InternalOrderService', params: { id: row.item.Id } });
    };
</script>
