<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold tw-ml-5 lg:tw-ml-6">Artigos</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn

                    color="primary"
                    @click="$router.push({ name: 'CreateArticle' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col>
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-bg-white tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <!-- <v-btn

                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-tune"></v-btn> -->
                <!-- <v-btn


                    size="large"
                    density="comfortable"
                    class="text-gray tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                :hover="true"
                :headers="headers"
                :items="articles"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                @click:row="onRowClick"
                item-value="Código"></v-data-table-server>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { WorkCenter } from '@/types';
    //import { getWorkCenters } from '@/api/workCenters';
    import { getArticles } from '@/api/articles';
    import { Ref, ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import _ from 'lodash';

    const { showLoader, hideLoader } = useLoader();
    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref<number>(1);
    const total = ref<number>(0);
    const pageSize = ref<number>(10);

    const articles = ref<any[]>([]);

    const headers = ref([
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Descrição', key: 'Descricao' },
    ]);

    async function onRowClick(row: any, o: any) {
        console.log(row);
        console.log(o.item);

        $router.push({ name: 'Article', params: { id: o.item.Artigo } });
    }

    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    async function init() {
        showLoader();
        let _page, _pageSize, _search;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            await getArticles(_page, _pageSize, search.value, null).then((response) => {
                articles.value = response.data.data;
                total.value = response.data.total;
            });
        } catch (error) {
            console.error(error);
        } finally {
            initialQuery.value = false;
            hideLoader();
        }
    }

    init();

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            if (initialQuery.value == false) {
                // search.value = $route.query.search as string ?? '';
                init();
            }
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        console.log('page or pageSize changed');
        console.log(page.value, pageSize.value);
        init();
    });
</script>

<style scoped></style>
