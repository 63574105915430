<template>
    <v-container
        class="flex-column justify-start"
        fluid>
        <v-row>
            <v-data-table
                :headers="headers"
                show-select
                v-model="selectedProcessedCalcNeeds"
                :items="filteredProcessedCalcNeeds"
                item-value="ID">
                <!-- filters -->
                <template v-slot:header.DescPedido="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isMenuOpen"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchDocumentNumber"
                                            @update:model-value="onSearchDocumentNumber"
                                            clearable
                                            label="Pesquisar"
                                            variant="underlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedDocumentNumbers"
                                    :key="item">
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedDocumentNumbers"
                                                :value="item"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllDocumentNumbers()"
                                                color="primary"
                                                v-model="allDocumentNumbersSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template v-slot:item.DataProp="{ item }">
                    <span>{{ formatDate(item.DataProp) }}</span>
                </template>
                <template v-slot:item.DataNecessidade="{ item }">
                    <span>{{ formatDate(item.DataNecessidade) }}</span>
                </template>
            </v-data-table>
            <v-row>
                <v-col class="tw-flex tw-justify-end tw-pt-4">
                    <v-btn
                        color="primary"
                        class="mr-2"
                        @click="createProductionOrders">
                        Gerar ordens de fabrico
                    </v-btn>
                    <v-btn
                        color="red"
                        @click="closeDialog">
                        Fechar
                    </v-btn>
                </v-col>
            </v-row>
        </v-row>
        <v-row>
            <v-alert
                class="tw-mt-3"
                v-if="errorMessage"
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
            <v-alert
                class="tw-mt-3"
                v-if="warningMessage"
                type="warning"
                variant="tonal"
                density="compact"
                >{{ warningMessage }}
            </v-alert>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import _, { set } from 'lodash';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { defineProps, defineEmits } from 'vue';
    import { createProductionOrder } from '@/api/productionOrders';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();

    const props = defineProps(['calcNeedsId', 'processedCalcNeed', 'dialog']);

    // Close button is in child component (this one)
    // So we need to emit an event to the parent component
    // To close the dialog
    const emit = defineEmits(['update:close']);

    const closeDialog = () => {
        emit('update:close', props.dialog);
    };

    // Function to Generate Unique ID to Processed Calc Needs
    // We Need This To Use In The Item-Value Property Of The V-Data-Table
    // Otherwise, The Selection Will Not Work
    const generateUniqueID = () => {
        return `${Date.now().toString(36)}-${Math.random().toString(36).slice(2, 11)}`;
    };

    const calcNeedsId = ref(props.calcNeedsId);
    const processedCalcNeeds = ref(props.processedCalcNeed);
    processedCalcNeeds.value = processedCalcNeeds.value.map((item: any) => ({
        ...item,
        ID: generateUniqueID(),
    }));
    const filteredProcessedCalcNeeds = ref(processedCalcNeeds.value);

    const selectedProcessedCalcNeeds = ref<any[]>([]);

    const documentNumbers = ref<any[]>([]);

    const errorMessage = ref('');
    const warningMessage = ref('');

    const headers = ref([
        {
            title: 'Artigo',
            key: 'Artigo',
        },
        {
            title: 'Alternativa',
            key: 'Alternativa',
        },
        {
            title: 'Descrição',
            key: 'Descricao',
        },
        {
            title: 'Tipo',
            key: 'DescricaoPedido',
        },
        {
            title: 'Pedido',
            key: 'DescPedido',
        },
        {
            title: 'Família',
            key: 'Familia',
        },
        {
            title: 'Unidade',
            key: 'Unidade',
        },
        {
            title: 'Data Proposta',
            key: 'DataProp',
        },
        {
            title: 'Data Necessidade',
            key: 'DataNecessidade',
        },
        {
            title: 'Qtd. Proposta',
            key: 'QtProposta',
        },
        {
            title: 'Qtd. Necessária',
            key: 'QtNecessaria',
        },
        {
            title: 'Qtd. Stock',
            key: 'QtStock',
        },
        {
            title: 'Qtd. OF',
            key: 'QtOrdemFabrico',
        },
        {
            title: 'Qtd. Reservada',
            key: 'QtReservada',
        },
        {
            title: 'Qtd. Enc. Fornecedores',
            key: 'QtOC',
        },
    ]);

    const isMenuOpen = ref(false);

    // Declare Filters Variables
    //
    // Document Numbers
    const selectedDocumentNumbers = ref<any[]>([]);
    const allDocumentNumbersSelected = ref(false);
    // Searched Document numbers Is An Array Of Projects
    // That Will Be Used To Handle The Search In Filters Menu, So It's Value Will Depend On The Search Input
    // DocumentNumbers Array Is The Variable That Contains All The Document Numbers, And It's Value Will Not Change
    const searchedDocumentNumbers = ref<any[]>([]);
    const searchDocumentNumber = ref('');

    // On Search Functions
    function onSearchDocumentNumber(str: string) {
        if (allDocumentNumbersSelected.value && searchedDocumentNumbers.value.length !== documentNumbers.value.length) allDocumentNumbersSelected.value = false;
        else if (!allDocumentNumbersSelected.value && searchedDocumentNumbers.value.length == documentNumbers.value.length) allDocumentNumbersSelected.value = true;

        searchDocumentNumber.value = str ?? '';

        searchedDocumentNumbers.value = [...new Set(documentNumbers.value.filter((docNumber) => docNumber.toLowerCase().includes(searchDocumentNumber.value.toLowerCase())))];
    }

    // Select All Document Numbers Function
    async function selectAllDocumentNumbers() {
        if (selectedDocumentNumbers.value.length == searchedDocumentNumbers.value.length) (selectedDocumentNumbers.value = []), (allDocumentNumbersSelected.value = false);
        else (selectedDocumentNumbers.value = searchedDocumentNumbers.value), (allDocumentNumbersSelected.value = true);
    }

    async function getDocumentNumbers() {
        let data = processedCalcNeeds.value.map((item) => item.DescPedido).filter((item) => item != null);
        let uniqueDocuments = [...new Set(data)];
        documentNumbers.value = uniqueDocuments;
        searchedDocumentNumbers.value = uniqueDocuments;
    }

    getDocumentNumbers();

    const createProductionOrders = async () => {
        // Check if there are selected items
        // If not, show an alert
        if (selectedProcessedCalcNeeds.value.length === 0) {
            warningMessage.value = 'Por favor, selecione pelo menos um artigo.';
            setTimeout(() => {
                warningMessage.value = '';
            }, 5000);
            return;
        } else {
            try {
                showLoader();
                for (let i = 0; i < selectedProcessedCalcNeeds.value.length; i++) {
                    let foundProcessedCalcNeed = processedCalcNeeds.value.find((item) => item.ID === selectedProcessedCalcNeeds.value[i]);
                    console.log('Found processed calc need:', foundProcessedCalcNeed);
                    await createProductionOrder(foundProcessedCalcNeed);
                }
            } catch (error) {
                console.log(error);
                errorMessage.value = 'Erro ao gerar as ordens de fabrico.';
                setTimeout(() => {
                    errorMessage.value = '';
                }, 5000);
            } finally {
                hideLoader();
                closeDialog();
                $alert.showAlert({
                    type: 'success',
                    text: 'Ordens de fabrico geradas com sucesso.',
                });
            }
        }
    };

    watch(selectedDocumentNumbers, async () => {
        if (selectedDocumentNumbers.value.length !== searchedDocumentNumbers.value.length) allDocumentNumbersSelected.value = false;
        else allDocumentNumbersSelected.value = true;

        filteredProcessedCalcNeeds.value = processedCalcNeeds.value.filter((item) => selectedDocumentNumbers.value.includes(item.DescPedido));
        selectedDocumentNumbers.value.length === 0 ? (filteredProcessedCalcNeeds.value = processedCalcNeeds.value) : null;
    });

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
