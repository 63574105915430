<template>
    <v-container
        v-if="article"
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold tw-ml-5 lg:tw-ml-6">{{ article.Artigo }}</h1>
            </v-col>
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <p class="tw-font-semibold">
                    {{ article.Descricao }}
                </p>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn
                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="x-large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
            <!-- <v-col
                v-if="isLoading"
                cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col> -->
        </v-row>
        <router-view
            v-if="article"
            :article="article"
            :families="families"
            :articleTypes="articleTypes"
            :units="units"
            :articleOperations="articleOperations"
            :parentArticles="parentArticles"
            @drilldown:article="drillDonwArticle" />
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';

    import { getArticle, getArticleOperations, getArticleParents } from '@/api/articles';
    import { getFamilies } from '@/api/families';
    import { getArticleTypes } from '@/api/articleTypes';
    import { getUnits } from '@/api/units';

    //import { createArticleWithPrice } from '@/api/articles';
    //import usePrimavera from '@/composables/primaveraParser';

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();

    // const navItems = [
    //     { title: 'Detalhes', to: { name: 'WorkCenterDetails' } },
    //     { title: 'Secções', to: { name: 'WorkCenterSections' } },
    //     { title: 'Calendários', to: { name: 'WorkCenterCalendars' } },
    //     { title: 'Artigos', to: { name: 'WorkCenterArticles' } },
    // ];

    const navItems = computed(() => [
        { title: 'Detalhes', to: { name: 'ArticleDetails' } },
        { title: 'Ficha Técnica', to: { name: 'ArticleTechnicalSheet' } },
        { title: 'Artigos Pai', to: { name: 'ArticleParentArticles' } }
    ]);

    const article = ref<any>(null);
    const families = ref();
    const articleTypes = ref();
    const units = ref();
    const articleOperations = ref();
    const parentArticles = ref();

    console.log('commit test')

    async function init() {
        showLoader();
        try {
            const articleId = $route.params.id;
            const [articleResponse, familiesResponse, articleTypesResponse, unitsResponse, articleOperationsResponse, articleParentsResponse] = await Promise.all([getArticle(articleId as string), getFamilies(), getArticleTypes(), getUnits(), getArticleOperations(articleId as string), getArticleParents(articleId as string)]);
            article.value = articleResponse.data;
            families.value = familiesResponse.data;
            articleTypes.value = articleTypesResponse.data;
            units.value = unitsResponse.data;
            articleOperations.value = articleOperationsResponse.data;
            parentArticles.value = articleParentsResponse.data;
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Ocorreu um erro ao carregar os dados do artigo.',
            });
        } finally {
            hideLoader();
        }
    }

    init();

    const drillDonwArticle = (to: any) => {
        console.log('changed');
        console.log(to);

        $router.push({ name: 'ArticleTechnicalSheet', params: { id: to } });
    };

    // add watcher to param id
    watch(
        () => $route.params.id,
        (newVal, oldVal) => {
            console.log('id changed');
            console.log(newVal, oldVal);
            init();
        },
    );
</script>

<style scoped></style>
