<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-5 tw-text-2xl tw-font-bold lg:tw-ml-6">Quadro de Controlo</h1>
            </v-col>
            <!-- <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    color="primary"
                    @click="$router.push({ name: 'CreateProductionOrder' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col> -->
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <!-- <v-btn

                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-tune"></v-btn> -->
                <!-- <v-btn

                    size="large"
                    density="comfortable"
                    class="text-gray tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                :headers="headers"
                :items="productionOrders"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                show-expand
                v-model:expanded="expanded"
                @click:row="onRowClick"
                item-value="OrdemFabrico">
                <!-- filters -->
                <template v-slot:header.CDU_CodigoProjeto="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isMenuOpen"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchProject"
                                            @update:model-value="onSearchProject"
                                            clearable
                                            label="Pesquisar"
                                            variant="underlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedProjects"
                                    :key="item">
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedProjects"
                                                :value="item"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllProjects()"
                                                color="primary"
                                                v-model="allProjectsSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <!-- expanded row -->
                <template v-slot:expanded-row="{ columns, item }">
                    <tr
                        v-if="item?.operations?.length > 0"
                        class="tw-bg-gray-100">
                        <td :colspan="columns.length">
                            <div class="tw-py-2">
                                <!-- Headers -->
                                <v-row class="tw-items-center tw-pb-2 tw-font-bold tw-text-secondary">
                                    <v-col>
                                        <p class="tw-text-sm">Sequência</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Operacão</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Descrição</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Confirmada</p>
                                    </v-col>
                                    <v-col class="tw-flex tw-justify-end">
                                        <p class="tw-text-sm">Qtd. Fabricada</p>
                                    </v-col>
                                    <v-col class="tw-flex tw-justify-end">
                                        <p class="tw-text-sm">Qtd. Rejeitada</p>
                                    </v-col>
                                    <v-col class="tw-flex tw-justify-end">
                                        <p class="tw-text-sm">Previsto</p>
                                    </v-col>
                                    <v-col class="tw-flex tw-justify-end">
                                        <p class="tw-text-sm">Consumido</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Unidade</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">CT</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Descrição CT</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm">Estado</p>
                                    </v-col>
                                    <v-col></v-col>
                                </v-row>
                                <v-divider class="tw-py-1"></v-divider>
                                <!-- Rows -->
                                <v-row
                                    class="tw-cursor-pointer tw-items-center"
                                    v-for="(operation, index) in item?.operations"
                                    :key="operation.IDOrdemFabricoOperacao"
                                    @click="onExpandedRowClick(operation)">
                                    <v-col>
                                        <p class="tw-text-sm tw-text-secondary">{{ operation.Operacao }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm tw-text-secondary">{{ operation.OperacaoProducao }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-sm tw-text-secondary">{{ operation.Descricao }}</p>
                                    </v-col>
                                    <v-col>
                                        <v-icon
                                            class=""
                                            :color="operation.Confirmada ? 'success' : 'error'">
                                            {{ operation.Confirmada ? 'mdi-check' : 'mdi-close' }}
                                        </v-icon>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-end tw-text-sm tw-text-secondary">{{ operation.QtFabricada }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-end tw-text-sm tw-text-secondary">{{ operation.QtRejeitada }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-end tw-text-sm tw-text-secondary">{{ formatTime(operation.TempoPrevisto) }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-text-end tw-text-sm tw-text-secondary">{{ formatTime(operation.TempoConsumido) }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-ml-2 tw-text-sm tw-text-secondary">{{ operation.UnidadeTempo }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-ml-2 tw-text-sm tw-text-secondary">{{ workCenter(operation.IDCentroTrabalho).name }}</p>
                                    </v-col>
                                    <v-col>
                                        <p class="tw-ml-2 tw-text-sm tw-text-secondary">{{ workCenter(operation.IDCentroTrabalho).description }}</p>
                                    </v-col>
                                    <v-col>
                                        <v-chip
                                            class="tw-ml-2"
                                            size="default"
                                            :color="operation.Estado == 7 ? 'secondary' : operation.Estado == 8 ? 'warning' : operation.Estado == 9 ? 'success' : 'grey'"
                                            dark>
                                            {{ operation.Estado == 7 ? 'Por Iniciar' : operation.Estado == 8 ? 'Em Curso' : operation.Estado == 9 ? 'Terminada' : 'Desconhecido' }}
                                        </v-chip>
                                    </v-col>
                                    <v-col cols="1">
                                        <!-- Start -->
                                        <!-- If Estado == Por Iniciar -->
                                        <v-btn
                                            v-if="operation.Estado == 7"
                                            @click.stop="onActionClick('operation', 'start', item.IDOrdemFabrico, operation.IDOrdemFabricoOperacao)"
                                            color="green"
                                            size="x-small"
                                            class="tw-mr-1"
                                            icon>
                                            <v-icon class="tw-text-xl tw-text-white">mdi-play</v-icon>
                                        </v-btn>
                                        <!-- Stop -->
                                        <!-- If Estado == Em Curso -->
                                        <v-btn
                                            v-if="operation.Estado == 8"
                                            @click.stop="onActionClick('operation', 'stop', item.IDOrdemFabrico, operation.IDOrdemFabricoOperacao)"
                                            color="red"
                                            class="tw-ml-2"
                                            size="x-small"
                                            icon>
                                            <v-icon class="tw-text-xl tw-text-white">mdi-stop</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <!-- <v-divider v-if="index !== item.operations.length - 1"></v-divider> -->
                                </v-row>
                            </div>
                        </td>
                    </tr>
                </template>
                <template v-slot:item.DataOrdemFabrico="{ item }">
                    <span>{{ formatDate(item.DataOrdemFabrico) }}</span>
                </template>
                <template v-slot:item.DataEntrega="{ item }">
                    <span>{{ formatDate(item.DataEntrega) }}</span>
                </template>
                <template v-slot:item.Estado="{ item }">
                    <v-chip
                        size="default"
                        :color="item.Estado == 2 ? 'secondary' : item.Estado == 3 ? 'warning' : item.Estado == 4 ? 'error' : item.Estado == 5 ? 'success' : 'grey'"
                        dark>
                        {{ item.Estado == 2 ? 'Por Iniciar' : item.Estado == 3 ? 'Em Curso' : item.Estado == 4 ? 'Suspensa' : item.Estado == 5 ? 'Terminada' : 'Desconhecido' }}
                    </v-chip>
                </template>
                <template v-slot:item.Descricao="{ item }">
                    <span>{{ description(item.Artigo) }}</span>
                </template>
                <template v-slot:item.DataInicio="{ item }">
                    <!-- Estado 5 = Terminada -->
                    <span v-if="item.Estado == 5">{{ formatDate(item.DataIniReal) }}</span>
                    <!-- Estado 2 = Por Iniciar | Estado 3 = Em Curso -->
                    <span v-else>{{ formatDate(item.DataIniPrevista) }}</span>
                </template>
                <template v-slot:item.DataFim="{ item }">
                    <!-- Estado 5 = Terminada -->
                    <span v-if="item.Estado == 5">{{ formatDate(item.DataFimReal) }}</span>
                    <!-- Estado 2 = Por Iniciar | Estado 3 = Em Curso -->
                    <span v-else>{{ formatDate(item.DataFimPrevista) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <div class="tw-flex tw-gap-1">
                        <!-- Start -->
                        <!-- If Estado == Por Iniciar || Estado == Suspensa -->
                        <v-btn
                            v-if="item.Estado == 2 || item.Estado == 4"
                            @click.stop="onActionClick('productionOrder', 'start', item.IDOrdemFabrico, null)"
                            color="green"
                            size="x-small"
                            class="tw-mr-1"
                            icon>
                            <v-icon class="tw-text-xl tw-text-white">mdi-play</v-icon>
                        </v-btn>
                        <!-- Pause -->
                        <!-- If Estado == Em Curso -->
                        <v-btn
                            v-if="item.Estado == 3"
                            @click.stop="onActionClick('productionOrder', 'pause', item.IDOrdemFabrico, null)"
                            color="gray"
                            size="x-small"
                            class="tw-mr-1"
                            icon>
                            <v-icon class="tw-text-xl tw-text-white">mdi-pause</v-icon>
                        </v-btn>
                        <!-- Stop -->
                        <!-- If Estado == Em Curso || Estado == Suspensa -->
                        <v-btn
                            v-if="item.Estado == 3 || item.Estado == 4"
                            @click.stop="onActionClick('productionOrder', 'stop', item.IDOrdemFabrico, null)"
                            color="red"
                            size="x-small"
                            icon>
                            <v-icon class="tw-text-xl tw-text-white">mdi-stop</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { getProductionOrders, changeProductionOrderStatus, changeProductionOrderOperationStatus } from '@/api/productionOrders';
    import { getArticles } from '@/api/articles';
    import { getWorkCenters } from '@/api/workCenters';
    import { ref, watch } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import _ from 'lodash';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();

    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref<number>(1);
    const total = ref<number>(0);
    const pageSize = ref<number>(10);
    const expanded = ref<any[]>([]);

    const productionOrders = ref<any[]>([]);
    const articles = ref<any[]>([]);
    const workCenters = ref<any[]>([]);
    const projects = ref<any[]>([]);

    const headers = ref([
        { title: 'Ordem de Fabrico', key: 'OrdemFabrico' },
        { title: 'Projeto', key: 'CDU_CodigoProjeto' },
        { title: 'Data', key: 'DataOrdemFabrico' },
        { title: 'Data Entrega', key: 'DataEntrega' },
        { title: 'Cliente', key: 'Cliente' },
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Qtd.', key: 'QtOrdemFabrico', align: 'end' },
        { title: 'Qtd. Fabricada', key: 'QtFabricada', align: 'end' },
        { title: 'Qtd. Conforme', key: 'QtNecessaria', align: 'end' },
        { title: 'Qtd. Rejeitada', key: 'QtRejeitada', align: 'end' },
        { title: 'Início', key: 'DataInicio' },
        { title: 'Fim', key: 'DataFim' },
        // { title: 'Alt', key: 'Alternativa' },
        { title: 'Estado', key: 'Estado' },
        { sortable: false, key: 'actions' },
    ]);

    const isMenuOpen = ref(false);

    // Declare Filters Variables
    //
    // Projects
    const selectedProjects = ref<string[]>([]);
    const allProjectsSelected = ref(false);
    // Searched Projects Is An Array Of Projects
    // That Will Be Used To Handle The Search In Filters Menu, So It's Value Will Depend On The Search Input
    // Projects Array Is The Variable That Contains All The Projects, And It's Value Will Not Change
    const searchedProjects = ref<any[]>([]);
    const searchProject = ref('');

    const filterByIds = ref<any>(null);

    async function onRowClick(row: any, o: any) {
        $router.push({ name: 'ProductionOrder', params: { id: o.item.IDOrdemFabrico } });
    }

    async function onExpandedRowClick(item: any) {
        $router.push({ name: 'Operation', params: { operationId: item.IDOrdemFabricoOperacao } });
    }

    async function onActionClick(type: string, action: string, productionOrderId: any, operationId: any) {
        console.log(action);
        if (type == 'productionOrder') {
            const result = await confirm.value.open(
                'Confirmação',
                `Tem a certeza que deseja <strong>${action == 'start' ? 'iniciar' : action == 'pause' ? 'pausar' : action == 'stop' ? 'terminar' : ''}</strong> a Ordem de Fabrico <strong>${productionOrders.value.find((po) => po.IDOrdemFabrico == productionOrderId).OrdemFabrico}</strong>?`,
                'Sim',
                'Não',
            );
            if (!result) return;

            showLoader();
            const status = action == 'start' ? 3 : action == 'pause' ? 4 : action == 'stop' ? 5 : null;
            if (status == null) return;
            await changeProductionOrderStatus(productionOrderId, status)
                .then(() => {
                    init();
                })
                .catch((error) => {
                    $alert.showAlert({
                        type: 'error',
                        text: 'Erro ao mudar o estado da Ordem de Fabrico. Por favor, tente novamente.',
                    });
                    console.error(error);
                })
                .finally(() => {
                    hideLoader();
                });
        }
        if (type == 'operation') {
            const result = await confirm.value.open(
                'Confirmação',
                `Tem a certeza que deseja <strong>${action == 'start' ? 'iniciar' : action == 'stop' ? 'terminar' : ''}</strong> a Operação <strong>${productionOrders.value.find((po) => po.IDOrdemFabrico == productionOrderId).operations.find((op) => op.IDOrdemFabricoOperacao == operationId).OperacaoProducao}</strong>?`,
                'Sim',
                'Não',
            );
            if (!result) return;

            showLoader();
            const status = action == 'start' ? 8 : action == 'stop' ? 9 : null;
            if (status == null) return;
            await changeProductionOrderOperationStatus(productionOrderId, operationId, status)
                .then(() => {
                    init();
                })
                .catch((error) => {
                    $alert.showAlert({
                        type: 'error',
                        text: 'Erro ao mudar o estado da Operação. Por favor, tente novamente.',
                    });
                    console.error(error);
                })
                .finally(() => {
                    hideLoader();
                });
        }
    }

    // On Search Functions
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function onSearchProject(str: string) {
        if (allProjectsSelected.value && searchedProjects.value.length !== projects.value.length) allProjectsSelected.value = false;
        else if (!allProjectsSelected.value && searchedProjects.value.length == projects.value.length) allProjectsSelected.value = true;

        searchProject.value = str ?? '';

        searchedProjects.value = [...new Set(projects.value.filter((project) => project.toLowerCase().includes(searchProject.value.toLowerCase())))];
    }

    // Select All Projects Function
    async function selectAllProjects() {
        if (selectedProjects.value.length == searchedProjects.value.length) (selectedProjects.value = []), (allProjectsSelected.value = false);
        else (selectedProjects.value = searchedProjects.value), (allProjectsSelected.value = true);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    async function init() {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            await getProductionOrders(_page, _pageSize, search.value)
                .then((response) => {
                    productionOrders.value = response.data.data;
                    total.value = response.data.total;
                })
                .finally(async () => {
                    const articlesIds = productionOrders.value.map((po) => po.Artigo);
                    const filterByIds = {
                        Artigo: articlesIds,
                    };
                    const [articlesResponse, workCentersResponse] = await Promise.all([getArticles(null, null, null, filterByIds), getWorkCenters(null, null, null, null)]);
                    articles.value = articlesResponse.data.data;
                    workCenters.value = workCentersResponse.data;
                    // articles.value = (await getArticles(null, null, null, articlesIds)).data;
                    initialQuery.value = false;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            hideLoader();
        }
    }

    init();

    async function getProjects() {
        try {
            await getProductionOrders(null, null, null, null)
                .then((response) => {
                    projects.value = response.data?.data.map((po) => po.CDU_CodigoProjeto).filter((project) => project !== null);
                    searchedProjects.value = [...new Set(projects.value)];
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    }

    getProjects();

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            if (initialQuery.value == false) {
                // search.value = $route.query.search as string ?? '';
                init();
            }
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        console.log('page or pageSize changed');
        console.log(page.value, pageSize.value);
        init();
    });

    watch(selectedProjects, async () => {
        if (selectedProjects.value.length !== searchedProjects.value.length) allProjectsSelected.value = false;
        else allProjectsSelected.value = true;

        filterByIds.value = selectedProjects.value.length > 0 ? { CDU_CodigoProjeto: selectedProjects.value } : null;

        await getProductionOrders(1, pageSize.value, search.value, filterByIds.value)
            .then((response) => {
                productionOrders.value = response.data.data;
                total.value = response.data.total;
                console.log(total.value);
            })
            .catch((error) => {
                console.error(error);
            });
    });

    // Return "Descricao" of an article
    const description = (articleId: string) => {
        const article = articles.value.find((a) => a.Artigo == articleId);
        return article?.Descricao ?? '';
    };

    // Return "Centro Trabalho" of an operation
    const workCenter = (workCenterId: any) => {
        const workCenter = workCenters.value.find((wc) => wc.IDCentroTrabalho == workCenterId);
        return workCenter ? { name: workCenter?.CentroTrabalho, description: workCenter?.Descricao } : { name: '', description: '' };
    };

    // Format time (e.g. 180 minutes to 3:00)
    const formatTime = (time: number) => {
        // Round the time value to 2 decimal places
        const roundedTime = parseFloat(time.toFixed(2));
        const hours = Math.floor(roundedTime / 60);
        const minutes = Math.floor(roundedTime % 60);

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
