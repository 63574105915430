<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-5 tw-text-2xl tw-font-bold lg:tw-ml-6">Diários de Trabalho</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    color="primary"
                    @click="$router.push({ name: 'CreateDailyWork' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :hover="true"
                        :items="dailyWorks">
                        <template v-slot:item.DataIni="{ item }">
                            <span>{{ formatDate(item.DataIni) }}</span>
                        </template>
                        <template v-slot:item.DataFim="{ item }">
                            <span>{{ formatDate(item.DataFim) }}</span>
                        </template></v-data-table
                    >
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { getDailyWorks } from '@/api/dailyWorks';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { ref } from 'vue';
    import { da } from 'vuetify/lib/locale/index.mjs';

    const dailyWorks = ref([]);
    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const done = ref(false);

    const headers = ref([
        { title: 'Ordem Fabrico', value: 'OrdemFabrico', sortable: true },
        { title: 'Operação', value: 'DescricaoOperacao', sortable: true },
        { title: 'Centro Trabalho', value: 'DescricaoCentroTrabalho', sortable: true },
        { title: 'Operador', value: 'NomeOperador', sortable: true },
        { title: 'Tipo Hora', value: 'DescricaoTipoHora', sortable: true },
        { title: 'Início', value: 'DataIni', sortable: true },
        { title: 'Fim', value: 'DataFim', sortable: true },
        { title: 'Quantidade Fabricada', value: 'QtFabricada', sortable: true },
        { title: 'Quantidade Rejeitada', value: 'QtRejeitada', sortable: true },
    ]);

    const init = async () => {
        try {
            showLoader();
            const dailyWorksResponse = await getDailyWorks();
            dailyWorks.value = dailyWorksResponse.data;
            done.value = true;
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar diários de trabalho',
            });
        } finally {
            hideLoader();
        }
    };

    init();

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
