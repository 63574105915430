<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold tw-ml-5 lg:tw-ml-6">{{ $t('articles.createArticle') }}</h1>
            </v-col>
        </v-row>

        <v-form
            ref="form"
            @submit.prevent="onSubmit">
            <v-row class="tw-mt-3">
                <v-col cols="12">
                    <h1 class="tw-text-xl tw-font-bold">Dados Gerais</h1>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.article') }}
                    </v-label>
                    <div>
                        <v-text-field
                            :placeholder="$t('articles.fields.article')"
                            :rules="[rules.required]"
                            v-model="data.article"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.description') }}
                    </v-label>
                    <div>
                        <v-text-field
                            :placeholder="$t('articles.fields.description')"
                            :rules="[rules.required]"
                            v-model="data.description"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.family') }}
                    </v-label>
                    <div>
                        <v-select
                            :rules="[rules.required]"
                            placeholder="Familia"
                            :items="families"
                            item-title="Descricao"
                            v-model:model-value="data.family"
                            @update:modelValue="setPrimaveraFields"
                            item-value="Familia">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.price') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :placeholder="$t('articles.fields.price')"
                            :rules="[rules.required, rules.numeric, rules.nonNegative]"
                            v-model="data.price"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.standardCostPrice') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :placeholder="$t('articles.fields.standardCostPrice')"
                            :rules="[rules.required, rules.numeric, rules.nonNegative]"
                            v-model="data.standardCostPrice"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.meanCostPrice') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :placeholder="$t('articles.fields.meanCostPrice')"
                            :rules="[rules.required, rules.numeric, rules.nonNegative]"
                            v-model="data.meanCostPrice"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.lastCostPrice') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :placeholder="$t('articles.fields.lastCostPrice')"
                            :rules="[rules.required, rules.numeric, rules.nonNegative]"
                            v-model="data.lastCostPrice"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.volume') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :placeholder="$t('articles.fields.volume')"
                            :rules="[rules.required, rules.numeric, rules.nonNegative]"
                            v-model="data.volume"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.weight') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :placeholder="$t('articles.fields.weight')"
                            :rules="[rules.required, rules.numeric, rules.nonNegative]"
                            v-model="data.weight"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.articleType') }}
                    </v-label>
                    <div>
                        <v-select
                            :rules="[rules.required]"
                            placeholder="Tipo de Artigo"
                            :items="articleTypes"
                            item-title="Descricao"
                            v-model:model-value="data.typeArticle"
                            item-value="TipoArtigo">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.usedInProduction') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            mandatory
                            :rules="[rules.required]"
                            color="primary"
                            v-model="data.usedInProduction"
                            border
                            class="tw-mt-auto">
                            <v-btn :value="true">
                                {{ $t('shared.yes') }}
                            </v-btn>
                            <v-btn :value="false">
                                {{ $t('shared.no') }}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.originGPR') }}
                    </v-label>
                    <div>
                        <v-select
                            :placeholder="$t('articles.fields.originGPR')"
                            :items="originGPR"
                            :disabled="!data.usedInProduction"
                            item-title="name"
                            :rules="data.usedInProduction ? [rules.required] : []"
                            v-model:model-value="data.GPROriginType"
                            item-value="id">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.productionType') }}
                    </v-label>
                    <div>
                        <v-select
                            :placeholder="$t('articles.fields.productionType')"
                            :items="productionType"
                            :disabled="!data.usedInProduction"
                            item-title="name"
                            :rules="data.usedInProduction ? [rules.required] : []"
                            v-model:model-value="data.productionType"
                            item-value="id">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.automaticComponentsProduction') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            mandatory
                            :rules="[rules.required]"
                            color="primary"
                            v-model="data.automaticComponentsProduction"
                            border
                            class="tw-mt-auto">
                            <v-btn :value="true">
                                {{ $t('shared.yes') }}
                            </v-btn>
                            <v-btn :value="false">
                                {{ $t('shared.no') }}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.IvaIncluded') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            mandatory
                            :rules="[rules.required]"
                            color="primary"
                            v-model="data.IvaIncluded"
                            border
                            class="tw-mt-auto">
                            <v-btn :value="true">
                                {{ $t('shared.yes') }}
                            </v-btn>
                            <v-btn :value="false">
                                {{ $t('shared.no') }}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.IVA') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :placeholder="$t('articles.fields.IVA')"
                            :rules="[rules.required]"
                            v-model="data.IVA"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.unit') }}
                    </v-label>
                    <div>
                        <v-select
                            :placeholder="$t('articles.fields.unit')"
                            :items="units"
                            item-title="Unidade"
                            :rules="[rules.required]"
                            v-model:model-value="data.unit"
                            @update:modelValue="setUnits"
                            item-value="Unidade">
                        </v-select>
                    </div>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.buyUnit') }}
                    </v-label>
                    <div>
                        <v-select
                            :placeholder="$t('articles.fields.buyUnit')"
                            :items="units"
                            item-title="Unidade"
                            :rules="[rules.required]"
                            v-model:model-value="data.buyUnit"
                            item-value="Unidade">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.sellUnit') }}
                    </v-label>
                    <div>
                        <v-select
                            :placeholder="$t('articles.fields.sellUnit')"
                            :items="units"
                            item-title="Unidade"
                            :rules="[rules.required]"
                            v-model:model-value="data.sellUnit"
                            item-value="Unidade">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.entranceUnit') }}
                    </v-label>
                    <div>
                        <v-select
                            :placeholder="$t('articles.fields.entranceUnit')"
                            :items="units"
                            item-title="Unidade"
                            :rules="[rules.required]"
                            v-model:model-value="data.entranceUnit"
                            item-value="Unidade">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.exitUnit') }}
                    </v-label>
                    <div>
                        <v-select
                            :placeholder="$t('articles.fields.exitUnit')"
                            :items="units"
                            item-title="Unidade"
                            :rules="[rules.required]"
                            v-model:model-value="data.exitUnit"
                            item-value="Unidade">
                        </v-select>
                    </div>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.convertionFactor') }}
                    </v-label>
                    <div>
                        <v-text-field
                            type="number"
                            :placeholder="$t('articles.fields.convertionFactor')"
                            v-model="data.convertionFactor"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.allowsReturn') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            mandatory
                            :rules="[rules.required]"
                            color="primary"
                            v-model="data.allowsReturns"
                            border
                            class="tw-mt-auto">
                            <v-btn :value="true">
                                {{ $t('shared.yes') }}
                            </v-btn>
                            <v-btn :value="false">
                                {{ $t('shared.no') }}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.batchTreatment') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            mandatory
                            :rules="[rules.required]"
                            color="primary"
                            v-model="data.batchTreatment"
                            border
                            class="tw-mt-auto">
                            <v-btn :value="true">
                                {{ $t('shared.yes') }}
                            </v-btn>
                            <v-btn :value="false">
                                {{ $t('shared.no') }}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.moveStocks') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            mandatory
                            :rules="[rules.required]"
                            color="primary"
                            v-model="data.moveStocks"
                            border
                            class="tw-mt-auto">
                            <v-btn value="S">
                                {{ $t('shared.yes') }}
                            </v-btn>
                            <v-btn value="N">
                                {{ $t('shared.no') }}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.calcNeeds') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            mandatory
                            :rules="[rules.required]"
                            color="primary"
                            v-model="data.calcNeeds"
                            border
                            class="tw-mt-auto">
                            <v-btn :value="true">
                                {{ $t('shared.yes') }}
                            </v-btn>
                            <v-btn :value="false">
                                {{ $t('shared.no') }}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    xl="6"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('articles.fields.observations') }}
                    </v-label>
                    <div>
                        <v-textarea
                            :placeholder="$t('articles.fields.observations')"
                            v-model="data.observations"></v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="tw-flex tw-items-end tw-justify-end">
                    <v-btn
                        color="primary"
                        type="submit"
                        >{{ $t('shared.save') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import useRules from '@/composables/rules';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { getFamilies } from '@/api/families';
    import { getArticleTypes } from '@/api/articleTypes';
    import { getUnits } from '@/api/units';
    import { createArticle } from '@/api/articles';
    import usePrimavera from '@/composables/primaveraParser';
    import { CalcNeed } from '../../types';
    import { VForm } from 'vuetify/lib/components/index.mjs';

    const rules = useRules();
    const form = ref<VForm>();
    const $alert = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { parseSocToPrimaveraArticle } = usePrimavera();

    const families = ref();
    const articleTypes = ref();
    const units = ref();

    const originGPR = ref([
        {
            id: 1,
            name: 'Fabrico',
        },
        {
            id: 2,
            name: 'Compra',
        },
        {
            id: 3,
            name: 'Fabrico/Compra',
        },
    ]);

    const productionType = ref([
        {
            id: 1,
            name: 'Assemblagem',
        },
        {
            id: 2,
            name: 'Desassemblagem',
        },
    ]);

    const data = ref({
        article: '', //
        description: '', //
        unit: null, //
        sellUnit: null, //
        buyUnit: null, //
        entranceUnit: null, //
        exitUnit: null, //
        convertionFactor: null, //
        weight: '0', //
        volume: '0', //
        IvaIncluded: true, //
        IVA: '23', //
        moveStocks: 'S', //
        family: null, //
        typeArticle: null, //
        usedInProduction: true, //
        GPROriginType: null, //
        allowsReturns: true, //
        //trata lotes
        batchTreatment: true, //
        observations: '', //
        calcNeeds: true, //
        price: '0',
        meanCostPrice: '0',
        lastCostPrice: '0',
        productionType: 1,
        automaticComponentsProduction: true,
        standardCostPrice: 0,

        //   "Marca": "",
        //   "FornecedorPrincipal": "",
        //   "PCMedio": 0,
        //   "PCUltimo": 0,
        //   "PCPadrao": 0,
        //   "OrdemDim": 0,
        //   "EmModoEdicao": false,
        //   "PCPadraoMAlt": 0,
        //   "ActualizaPCPadrao": true
    });

    const setUnits = (value: any) => {
        data.value.sellUnit = value;
        data.value.entranceUnit = value;
        data.value.exitUnit = value;
    };

    const setPrimaveraFields = (value: any) => {
        console.log(value);
        const famData = familyData.value[value] as any || null;
        if (!famData) {
                data.value.moveStocks = '';
                data.value.allowsReturns = false;
                data.value.calcNeeds = true;
                data.value.typeArticle = null;
                data.value.usedInProduction = false;
                data.value.GPROriginType = null;
                data.value.batchTreatment = false;
            return;
        };

        data.value.moveStocks = famData.moveStocks;
        data.value.allowsReturns = famData.allowsReturns;
        data.value.calcNeeds = famData.calcNeeds;
        data.value.typeArticle = famData.typeArticle;
        data.value.usedInProduction = famData.usedInProduction;
        data.value.GPROriginType = famData.GPROriginType;
        data.value.batchTreatment = famData.batchTreatment;
        //data.value.typeArticle = articleTypes.value.find((type: any) => type.TipoArtigo === famData.typeArticle);

        console.log(data.value);


    };

    const familyData = ref({
        '001': {
            typeArticle: "6",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 2,
            batchTreatment: false,
            calcNeeds: true
        },
        '002':{
            typeArticle: "6",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 2,
            batchTreatment: false,
            calcNeeds: true
        },
        '003':{
            typeArticle: "6",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 2,
            batchTreatment: false,
            calcNeeds: true
        },
        '004':{
            typeArticle: "6",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 2,
            batchTreatment: false,
            calcNeeds: true
        },
        '005':{
            typeArticle: "6",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 2,
            batchTreatment: false,
            calcNeeds: true
        },
        '006':{
            typeArticle: "6",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 2,
            batchTreatment: false,
            calcNeeds: true
        },
        '007':{
            typeArticle: "11",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: false,
            GPROriginType: 2,
            batchTreatment: false,
            calcNeeds: false
        },
        '008':{
            typeArticle: "4",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 1,
            batchTreatment: true,
            calcNeeds: true
        },
        '009':{
            typeArticle: "6",
            moveStocks: 'N',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 2,
            batchTreatment: false,
            calcNeeds: true
        },
        '011': {
            typeArticle: "0",
            moveStocks: 'N',
            allowsReturns: false,
            usedInProduction: false,
            GPROriginType: null,
            batchTreatment: null,
            calcNeeds: false
        },
        '012': {
            typeArticle: "11",
            moveStocks: 'N',
            allowsReturns: true,
            usedInProduction: false,
            GPROriginType: null,
            batchTreatment: null,
            calcNeeds: false
        },
        '022': {
            typeArticle: "13",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 1,
            batchTreatment: true,
            calcNeeds: true
        },
        '024': {
            typeArticle: "13",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 1,
            batchTreatment: true,
            calcNeeds: true
        },
        '025': {
            typeArticle: "13",
            moveStocks: 'S',
            allowsReturns: true,
            usedInProduction: true,
            GPROriginType: 1,
            batchTreatment: true,
            calcNeeds: true
        },
    }) as any;

    const onSubmit = async () => {
        if (!form.value) return;
        const { valid } = await form.value.validate();
        if (!valid) return;

        try {
            showLoader();
            const parsedBody = parseSocToPrimaveraArticle(data.value);

            console.log(parsedBody);

            const response = await createArticle(parsedBody);
            $alert.showAlert({
                type: 'success',
                text: 'Artigo criado com sucesso.',
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao criar artigo. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    async function init() {
        showLoader();
        try {
            const [familiesResponse, articleTypesResponse, unitsResponse] = await Promise.all([getFamilies(), getArticleTypes(), getUnits()]);
            families.value = familiesResponse.data;
            articleTypes.value = articleTypesResponse.data;
            units.value = unitsResponse.data;
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar dados. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    }

    init();
</script>

<style scoped></style>
