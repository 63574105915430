<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-5 tw-text-2xl tw-font-bold lg:tw-ml-6">{{ $t('dailyWork.createDailyWorks') }}</h1>
            </v-col>
        </v-row>

        <v-form
            v-if="done"
            ref="form"
            @submit.prevent="onSubmit">
            <!-- <v-row class="tw-mt-3">
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('dailyWork.fields.productionOrder') }}
                    </v-label>
                    <div>
                        <v-autocomplete
                            :items="productionOrders"
                            @update:modelValue="data.registers = []"
                            v-model="data.productionOrder"
                            :placeholder="$t('dailyWork.fields.productionOrder')"
                            item-title="OrdemFabrico"
                            :rules="[rules.required]"
                            item-value="IDOrdemFabrico">
                        </v-autocomplete>
                    </div>
                </v-col>
            </v-row> -->

            <!-- <v-row
                class="tw-mt-3"
                v-if="data.productionOrder"> -->
            <v-row class="tw-mt-5">
                <v-data-table
                    :headers="headers"
                    :items="data.registers">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <!-- <v-toolbar-title>Lista de Registos</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical></v-divider> -->
                            <!-- <v-spacer></v-spacer> -->
                            <v-dialog
                                v-model="dialog"
                                max-width="80%">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        color="primary"
                                        dark
                                        v-bind="props">
                                        Novo Registo
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <v-container>
                                            <span class="text-h5">{{ formTitle }}</span>
                                        </v-container>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-form
                                            ref="form2"
                                            @submit.prevent="save">
                                            <v-container>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-autocomplete
                                                            :items="productionOrders"
                                                            @update:modelValue="
                                                                (value : string) => {
                                                                    if (!editedItem.productionOrder || editedItem.productionOrder !== value) {
                                                                        editedItem.operation = '';
                                                                        editedItem.stock = false;
                                                                        editedItem.order = '';
                                                                    }
                                                                }
                                                            "
                                                            v-model="editedItem.productionOrder"
                                                            :placeholder="$t('dailyWork.fields.productionOrder')"
                                                            item-title="OrdemFabrico"
                                                            :rules="[rules.required]"
                                                            item-value="IDOrdemFabrico"
                                                            label="Ordem de Fabrico">
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-autocomplete
                                                            v-model="editedItem.operation"
                                                            @update:modelValue="isTheLastOperation"
                                                            :placeholder="$t('dailyWork.fields.operation')"
                                                            item-title="OperacaoProducao"
                                                            :rules="[rules.required]"
                                                            item-value="IDOrdemFabricoOperacao"
                                                            :items="getOperations(editedItem.productionOrder)"
                                                            label="Operação">
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-autocomplete
                                                            v-model="editedItem.worker"
                                                            placeholder="Operador"
                                                            item-title="Nome"
                                                            :rules="[rules.required]"
                                                            item-value="IDOperador"
                                                            :items="workers"
                                                            label="Operador">
                                                        </v-autocomplete>

                                                        <!-- <v-text-field
                                                        v-model="editedItem.worker"
                                                        label="Operador"></v-text-field> -->
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-autocomplete
                                                            v-model="editedItem.hourType"
                                                            placeholder="Tipo Hora"
                                                            item-title="Descricao"
                                                            :rules="[rules.required]"
                                                            item-value="IDTipoHora"
                                                            :items="hoursTypes"
                                                            label="Tipo Hora">
                                                        </v-autocomplete>

                                                        <!--
                                                    <v-text-field
                                                        v-model="editedItem.hourType"
                                                        label="Tipo Hora"></v-text-field> -->
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="datetime-local"
                                                            :rules="[rules.required]"
                                                            v-model="editedItem.start"
                                                            label="Inicio"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="datetime-local"
                                                            :rules="[rules.required, rules.afterDate(editedItem.end, editedItem.start)]"
                                                            v-model="editedItem.end"
                                                            label="Fim"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        xl="6"
                                                        class="tw-flex tw-flex-col tw-justify-between">
                                                        <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                                                            {{ 'Operação terminada' + '?' }}
                                                        </v-label>
                                                        <div>
                                                            <v-btn-toggle
                                                                mandatory
                                                                v-model="editedItem.operationFinished"
                                                                :rules="[rules.required]"
                                                                color="primary"
                                                                border
                                                                class="tw-mt-auto">
                                                                <v-btn :value="true">
                                                                    {{ $t('shared.yes') }}
                                                                </v-btn>
                                                                <v-btn :value="false">
                                                                    {{ $t('shared.no') }}
                                                                </v-btn>
                                                            </v-btn-toggle>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-divider class="my-2"></v-divider>
                                                        <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Quantidades </v-label>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="number"
                                                            :rules="[rules.required, rules.nonNegative]"
                                                            v-model="editedItem.quantityConform"
                                                            label="Quantidade Conforme"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="number"
                                                            :rules="[rules.required, rules.nonNegative]"
                                                            v-model="editedItem.quantityRejected"
                                                            label="Quantidade Rejeitada"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="8"
                                                        sm="12">
                                                        <div class="tw-flex tw-gap-x-2">
                                                            <div class="tw-flex tw-items-center">
                                                                <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Entrada Stock: </v-label>
                                                                <v-checkbox-btn
                                                                    color="primary"
                                                                    v-model="editedItem.stock"
                                                                    @update:modelValue="
                                                                        () => {
                                                                            if (editedItem.stock) {
                                                                                editedItem.order = productionOrders.find((po) => po.IDOrdemFabrico === editedItem.productionOrder).OrdemFabrico;
                                                                            } else {
                                                                                editedItem.order = '';
                                                                            }
                                                                        }
                                                                    "></v-checkbox-btn>
                                                            </div>
                                                            <v-text-field
                                                                :disabled="!editedItem.stock"
                                                                label="Lote"
                                                                v-model="editedItem.order"
                                                                hide-details></v-text-field>
                                                        </div>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        xl="6"
                                                        class="tw-flex tw-flex-col tw-justify-between">
                                                        <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                                                            {{ 'Consumo de componentes' + '?' }}
                                                        </v-label>
                                                        <div>
                                                            <v-btn-toggle
                                                                mandatory
                                                                v-model="editedItem.consumption"
                                                                :rules="[rules.required]"
                                                                color="primary"
                                                                border
                                                                class="tw-mt-auto">
                                                                <v-btn :value="true">
                                                                    {{ $t('shared.yes') }}
                                                                </v-btn>
                                                                <v-btn :value="false">
                                                                    {{ $t('shared.no') }}
                                                                </v-btn>
                                                            </v-btn-toggle>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="secondary"
                                            variant="text"
                                            @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            variant="text"
                                            @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <ConfirmDialog ref="confirm" />
                        </v-toolbar>
                    </template>
                    <template v-slot:item.productionOrder="{ item }">
                        {{ getProductionOrder(item.productionOrder) }}
                    </template>
                    <template v-slot:item.operation="{ item }">
                        {{ getOperationDescription(item.productionOrder, item.operation) }}
                    </template>
                    <template v-slot:item.operationFinished="{ item }">
                        <v-chip
                            size="default"
                            :color="item.operationFinished ? 'success' : 'error'"
                            dark>
                            {{ item.operationFinished ? 'Sim' : 'Não' }}
                        </v-chip>
                    </template>
                    <template v-slot:item.worker="{ item }">
                        {{ getWorkerName(item.worker) }}
                    </template>
                    <template v-slot:item.hourType="{ item }">
                        {{ getHourTypeDescription(item.hourType) }}
                    </template>
                    <template v-slot:item.start="{ item }">
                        {{ formatDate(item.start) }}
                    </template>
                    <template v-slot:item.end="{ item }">
                        {{ formatDate(item.end) }}
                    </template>
                    <template v-slot:item.stock="{ item }">
                        <v-icon :color="item.stock ? 'success' : 'error'">
                            {{ item.stock ? 'mdi-check' : 'mdi-close' }}
                        </v-icon>
                    </template>
                    <template v-slot:item.consumption="{ item }">
                        <v-icon :color="item.consumption ? 'success' : 'error'">
                            {{ item.consumption ? 'mdi-check' : 'mdi-close' }}
                        </v-icon>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon
                            class="me-2"
                            size="small"
                            @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            size="small"
                            @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-row>

            <v-row class="tw-mt-3">
                <v-col
                    cols="12"
                    class="tw-flex tw-justify-end">
                    <v-btn
                        class="tw-mr-3"
                        color="primary"
                        @click="onSubmit"
                        >{{ $t('shared.save') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { ref, computed, watch, nextTick } from 'vue';
    import useRules from '@/composables/rules';
    import { getProductionOrders } from '@/api/productionOrders';
    import { getWorkers } from '@/api/workers';
    import { getHoursTypes } from '@/api/hoursTypes';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import { createDailyWork } from '@/api/dailyWorks';
    import { update } from 'lodash';

    const rules = useRules();
    const $alert = useAlert();
    const { showLoader, hideLoader } = useLoader();

    const form = ref<VForm>();
    const form2 = ref<VForm>();
    const done = ref(false);
    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const data = ref({
        registers: [],
    });

    const productionOrders = ref([] as any[]);
    const workers = ref([] as any[]);
    const hoursTypes = ref([] as any[]);

    const onSubmit = async () => {
        if (!form.value) return;
        const { valid } = await form.value.validate();
        if (!valid) return;

        // check if there are registers
        if (data.value.registers.length === 0) {
            $alert.showAlert({
                type: 'error',
                text: 'Deve adicionar pelo menos um registo.',
            });
            return;
        }

        console.log('submit');

        console.log('data', data.value);

        data.value.registers.forEach((register: any) => {
            // Use optional chaining and nullish coalescing to handle potential undefined/null values
            const productionOrder = productionOrders.value.find((po) => po.IDOrdemFabrico === register.productionOrder);

            register['article'] = productionOrder?.Artigo;

            if (productionOrder?.operations) {
                const operation = productionOrder.operations.find((op: any) => op.IDOrdemFabricoOperacao === register.operation);
                // Assign workCenter only if the operation exists
                if (operation) {
                    register['workCenter'] = operation.IDCentroTrabalho;
                }
            }
        });

        console.log('data', data.value);

        try {
            showLoader();
            await createDailyWork(data.value);
            $alert.showAlert({
                type: 'success',
                text: 'Registo criado com sucesso.',
            });
        } catch (error: any) {
            console.error('error', error);
            $alert.showAlert({
                type: 'error',
                text: error?.response?.data?.error?.message || 'Erro ao criar registo. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    const getProductionOrder = (productionOrderId: string) => {
        const productionOrder = productionOrders.value.find((po: any) => po.IDOrdemFabrico === productionOrderId);
        return productionOrder ? productionOrder.OrdemFabrico : '';
    };

    const getOperations = (productionOrderId: string) => {
        const productionOrder = productionOrders.value.find((po: any) => po.IDOrdemFabrico === productionOrderId);
        return productionOrder ? productionOrder.operations : [];
    };

    const getOperationDescription = (productionOrderId: string, operationId: string) => {
        const productionOrder = productionOrders.value.find((po: any) => po.IDOrdemFabrico === productionOrderId);
        const operation = productionOrder?.operations.find((op: any) => op.IDOrdemFabricoOperacao === operationId);
        return operation ? operation.Descricao : '';
    };

    const getWorkerName = (workerId: string) => {
        const worker = workers.value.find((w) => w.IDOperador === workerId);
        return worker ? worker.Nome : '';
    };

    const getHourTypeDescription = (hourTypeId: string) => {
        const hourType = hoursTypes.value.find((ht) => ht.IDTipoHora === hourTypeId);
        return hourType ? hourType.Descricao : '';
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };

    const init = async () => {
        console.log('init');
        showLoader();

        try {
            const [responseProductionOrders, workersResponse, hoursTypesResponse] = await Promise.all([getProductionOrders(null, null, null), getWorkers(), getHoursTypes()]);

            productionOrders.value = responseProductionOrders.data?.data;
            workers.value = workersResponse.data;
            hoursTypes.value = hoursTypesResponse.data;

            console.log('productionOrders', productionOrders.value);
            done.value = true;
        } catch (error) {
            console.error('error', error);
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar dados. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    init();

    /**
     *
     *
     * INFO TO DATATABLE
     *
     *
     */

    const dialog = ref(false);
    const dialogDelete = ref(false);
    const headers = ref([
        {
            title: 'Ordem de Fabrico',
            key: 'productionOrder',
            sortable: false,
        },
        {
            title: 'Operação',
            key: 'operation',
            sortable: false,
        },
        {
            title: 'Operador',
            key: 'worker',
            sortable: false,
        },
        {
            title: 'Operação Terminada',
            key: 'operationFinished',
            sortable: false,
        },
        {
            title: 'Tipo Hora',
            key: 'hourType',
            sortable: false,
        },
        {
            title: 'Inicio',
            key: 'start',
            sortable: false,
        },
        {
            title: 'Fim',
            key: 'end',
            sortable: false,
        },
        {
            title: 'Quantidade Conforme',
            key: 'quantityConform',
            sortable: false,
        },
        {
            title: 'Quantidade Rejeitada',
            key: 'quantityRejected',
            sortable: false,
        },
        {
            title: 'Entrada Stock',
            key: 'stock',
            sortable: false,
        },
        {
            title: 'Lote',
            key: 'order',
            sortable: false,
        },
        {
            title: 'Consumo de Componentes',
            key: 'consumption',
            sortable: false,
        },

        { title: 'Ações', key: 'actions', sortable: false },
    ]);

    const editedIndex = ref(-1);

    const editedItem = ref({
        productionOrder: '',
        operation: '',
        worker: '',
        operationFinished: false,
        hourType: '',
        start: '',
        end: '',
        quantityConform: '',
        quantityRejected: '',
        stock: false,
        order: '',
        consumption: false,
    });

    const defaultItem = ref({
        productionOrder: '',
        operation: '',
        worker: '',
        operationFinished: false,
        hourType: '',
        start: '',
        end: '',
        quantityConform: '',
        quantityRejected: '',
        stock: false,
        order: '',
        consumption: false,
    });

    const formTitle = computed(() => (editedIndex.value === -1 ? 'Novo Registo' : 'Editar Registo'));

    // add watchers to dialog and dialogDelete
    watch(dialog, (value) => {
        if (!value) {
            close();
        }
    });

    // watch selected operation, to check if it's the last operation
    // so we can set stock to true
    // watch(
    //     () => editedItem.value.operation,
    //     (value) => {
    //         const productionOrder = productionOrders.value.find((po) => po.IDOrdemFabrico === editedItem.value.productionOrder);
    //         const operation = productionOrder?.operations.find((op) => op.IDOrdemFabricoOperacao === value);
    //         if (operation) {
    //             const isLastOperation = operation.IDOrdemFabricoOperacao === productionOrder.operations[productionOrder.operations.length - 1].IDOrdemFabricoOperacao;

    //             if (isLastOperation) {
    //                 editedItem.value.stock = true;
    //                 editedItem.value.order = productionOrder.OrdemFabrico;
    //             } else {
    //                 editedItem.value.stock = false;
    //                 editedItem.value.order = '';
    //             }
    //         }
    //     },
    // );

    function isTheLastOperation(value: string) {
        const productionOrder = productionOrders.value.find((po) => po.IDOrdemFabrico === editedItem.value.productionOrder);
        const operation = productionOrder?.operations.find((op) => op.IDOrdemFabricoOperacao === value);
        if (operation) {
            const isLastOperation = operation.IDOrdemFabricoOperacao === productionOrder.operations[productionOrder.operations.length - 1].IDOrdemFabricoOperacao;

            if (isLastOperation) {
                editedItem.value.stock = true;
                editedItem.value.order = productionOrder.OrdemFabrico;
            } else {
                editedItem.value.stock = false;
                editedItem.value.order = '';
            }
        }
    }

    function editItem(item: any) {
        editedIndex.value = data.value.registers.indexOf(item);
        editedItem.value = Object.assign({}, item);
        dialog.value = true;
    }

    async function deleteItem(item: any) {
        editedIndex.value = data.value.registers.indexOf(item);
        editedItem.value = Object.assign({}, item);

        if (confirm.value) {
            const result = await confirm.value.open('Confirmação', 'Tem a certeza que pretende apagar este registo?', 'Sim', 'Não');

            if (result) {
                deleteItemConfirm();
            }
        }
    }

    function deleteItemConfirm() {
        data.value.registers.splice(editedIndex.value, 1);
        nextTick(() => {
            editedItem.value = Object.assign({}, defaultItem.value);
            editedIndex.value = -1;
        });
    }

    function close() {
        dialog.value = false;
        nextTick(() => {
            editedItem.value = Object.assign({}, defaultItem.value);
            editedIndex.value = -1;
        });
    }

    async function save() {
        if (!form2.value) return;
        const { valid } = await form2.value.validate();
        if (!valid) return;

        if (editedIndex.value > -1) {
            Object.assign(data.value.registers[editedIndex.value], editedItem.value);
        } else {
            data.value.registers.push(editedItem.value);
        }
        console.log('data', data.value);
        close();
    }
</script>

<style scoped></style>
