<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-5 tw-text-2xl tw-font-bold lg:tw-ml-6">Ordens de Fabrico</h1>
            </v-col>
            <!-- <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn

                    color="primary"
                    @click="$router.push({ name: 'CreateProductionOrder' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col> -->
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-delete"
                    @click="onDelete"></v-btn>
                <!-- <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                :headers="headers"
                :items="productionOrders"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                show-select
                v-model="selectedItems"
                @click:row="onRowClick"
                item-value="IDOrdemFabrico">
                <!-- filters -->
                <template v-slot:header.CDU_CodigoProjeto="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isMenuOpen"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchProject"
                                            @update:model-value="onSearchProject"
                                            clearable
                                            label="Pesquisar"
                                            variant="underlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedProjects"
                                    :key="item">
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedProjects"
                                                :value="item"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllProjects()"
                                                color="primary"
                                                v-model="allProjectsSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template v-slot:item.Descricao="{ item }">
                    <span>{{ description(item.Artigo) }}</span>
                </template>
                <template v-slot:item.DataOrdemFabrico="{ item }">
                    <span>{{ formatDate(item.DataOrdemFabrico) }}</span>
                </template>
                <template v-slot:item.Confirmada="{ item }">
                    <v-icon :color="item.Confirmada ? 'success' : 'error'">
                        {{ item.Confirmada ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:item.Estado="{ item }">
                    <v-chip
                        size="default"
                        :color="item.Estado == 2 ? 'secondary' : item.Estado == 3 ? 'warning' : item.Estado == 4 ? 'error' : item.Estado == 5 ? 'success' : 'grey'"
                        dark>
                        {{ item.Estado == 2 ? 'Por Iniciar' : item.Estado == 3 ? 'Em Curso' : item.Estado == 4 ? 'Suspensa' : item.Estado == 5 ? 'Terminada' : 'Desconhecido' }}
                    </v-chip>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { WorkCenter } from '@/types';
    import { getProductionOrders, deleteProductionOrders } from '@/api/productionOrders';
    import { getArticles } from '@/api/articles';
    import { Ref, ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import _, { filter, initial } from 'lodash';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import { useAlert } from '@/composables/useAlert';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref<number>(1);
    const total = ref<number>(0);
    const pageSize = ref<number>(10);
    const selectedItems = ref<any[]>([]);

    const productionOrders = ref<any[]>([]);
    const articles = ref<any[]>([]);
    const projects = ref<any[]>([]);

    const headers = ref([
        { title: 'Ordem de Fabrico', key: 'OrdemFabrico' },
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Projeto', key: 'CDU_CodigoProjeto' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Data', key: 'DataOrdemFabrico' },
        { title: 'Confirmada', key: 'Confirmada' },
        { title: 'Valorizada', key: '' },
        { title: 'Estado', key: 'Estado' },
    ]);

    const isMenuOpen = ref(false);

    // Declare Filters Variables
    //
    // Projects
    const selectedProjects = ref<string[]>([]);
    const allProjectsSelected = ref(false);
    // Searched Projects Is An Array Of Projects
    // That Will Be Used To Handle The Search In Filters Menu, So It's Value Will Depend On The Search Input
    // Projects Array Is The Variable That Contains All The Projects, And It's Value Will Not Change
    const searchedProjects = ref<any[]>([]);
    const searchProject = ref('');

    const filterByIds = ref<any>(null);

    async function onRowClick(row: any, o: any) {
        $router.push({ name: 'ProductionOrder', params: { id: o.item.IDOrdemFabrico } });
    }

    // On Search Functions
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function onSearchProject(str: string) {
        if (allProjectsSelected.value && searchedProjects.value.length !== projects.value.length) allProjectsSelected.value = false;
        else if (!allProjectsSelected.value && searchedProjects.value.length == projects.value.length) allProjectsSelected.value = true;

        searchProject.value = str ?? '';

        searchedProjects.value = [...new Set(projects.value.filter((project) => project.toLowerCase().includes(searchProject.value.toLowerCase())))];
    }

    // Select All Projects Function
    async function selectAllProjects() {
        if (selectedProjects.value.length == searchedProjects.value.length) (selectedProjects.value = []), (allProjectsSelected.value = false);
        else (selectedProjects.value = searchedProjects.value), (allProjectsSelected.value = true);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    async function init() {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            await getProductionOrders(_page, _pageSize, search.value, filterByIds.value)
                .then((response) => {
                    productionOrders.value = response.data.data;
                    total.value = response.data.total;
                })
                .finally(async () => {
                    const articlesIds = productionOrders.value.map((po) => po.Artigo);
                    const filterByIds = {
                        Artigo: articlesIds,
                    };
                    articles.value = (await getArticles(null, null, null, filterByIds)).data.data;

                    initialQuery.value = false;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
        }
    }

    init();

    async function getProjects() {
        try {
            await getProductionOrders(null, null, null, null)
                .then((response) => {
                    let data = response.data?.data.map((po) => po.CDU_CodigoProjeto).filter((project) => project !== null);
                    let uniqueProjects = [...new Set(data)];
                    projects.value = uniqueProjects;
                    searchedProjects.value = uniqueProjects;
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    }

    getProjects();

    const onDelete = async () => {
        try {
            if (selectedItems.value.length == 0) {
                $alert.showAlert({
                    type: 'warning',
                    text: 'Selecione pelo menos uma Ordem de Fabrico para eliminar.',
                });
                return;
            }
            const result = await confirm.value.open('Confirmação', `As Ordens de Fabrico selecionadas estão confirmadas. Tem a certeza que deseja eliminá-las?`, 'Sim', 'Não');
            if (!result) return;

            showLoader();
            // Disconfirm the production order
            // So then we can delete it
            await deleteProductionOrders(selectedItems.value as string[]).then(() => {
                init();
                $alert.showAlert({
                    type: 'success',
                    text: 'Ordens de Fabrico eliminadas com sucesso.',
                });
            });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text:
                    (error?.response?.data?.error?.message !== undefined || error?.response?.data?.error?.message !== null) && error?.response?.data?.error?.status !== 500
                        ? error.response.data.error.message
                        : 'Erro ao eliminar as Ordens de Fabrico. Por favor, tente novamente.',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            if (initialQuery.value == false) {
                // search.value = $route.query.search as string ?? '';
                init();
            }
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        console.log('page or pageSize changed');
        console.log(page.value, pageSize.value);
        init();
    });

    watch(selectedProjects, async () => {
        if (selectedProjects.value.length !== searchedProjects.value.length) allProjectsSelected.value = false;
        else allProjectsSelected.value = true;

        filterByIds.value = selectedProjects.value.length > 0 ? { CDU_CodigoProjeto: selectedProjects.value } : null;

        await getProductionOrders(1, pageSize.value, search.value, filterByIds.value)
            .then((response) => {
                productionOrders.value = response.data.data;
                total.value = response.data.total;
                console.log(total.value);
            })
            .catch((error) => {
                console.error(error);
            });
    });

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };

    // Return "Descricao" of an article
    const description = (articleId: string) => {
        const article = articles.value.find((a) => a.Artigo == articleId);
        return article?.Descricao ?? '';
    };
</script>

<style scoped></style>
