<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold tw-ml-5 lg:tw-ml-6">{{ workCenter?.name }}</h1>

            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn

                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="x-large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
        </v-row>
        <router-view
            v-if="workCenter && sections && workCalendars"
            :workCenter="workCenter"
            :sections="sections"
            :workCalendars="workCalendars" />
    </v-container>
</template>

<script setup lang="ts">
    import { WorkCenter, WorkCenterSOC, Section, WorkCalendar } from '@/types';
    import { getWorkCenter } from '@/api/workCenters';
    import { ref, computed } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import usePrimaveraParser from '@/composables/primaveraParser';
    import { getSections } from '@/api/sections';
    import { getWorkCalendars } from '@/api/workCalendars';

    const { showLoader, hideLoader } = useLoader();
    const $router = useRouter();
    const $route = useRoute();
    const { parseSocToPrimaveraWorkCenter, parsePrimaveraToSocWorkCenter } = usePrimaveraParser();

    const workCenter = ref<WorkCenterSOC | null>(null);

    const sections = ref([] as Section[]);
    const workCalendars = ref([] as WorkCalendar[]);

    const navItems = computed(() => [
        { title: 'Detalhes', to: { name: 'WorkCenterDetails', params: { id: $route.params.id } } },
        { title: 'Ordens de Produção', to: { name: 'WorkCenterOrders', params: { id: $route.params.id } } },
        { title: 'Operações', to: { name: 'WorkCenterOperations', params: { id: $route.params.id } } },
        { title: 'Recursos', to: { name: 'WorkCenterResources', params: { id: $route.params.id } } },
    ]);

    async function init() {
        showLoader();
        console.log('init');
        try {
            const [sectionsResponse, workCalendarsResponse, workCenterResponse] = await Promise.all([getSections(), getWorkCalendars(), getWorkCenter(Number($route.params.id))]);
            sections.value = sectionsResponse.data;
            workCalendars.value = workCalendarsResponse.data;
            workCenter.value = parsePrimaveraToSocWorkCenter(workCenterResponse.data);
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    }

    init();
</script>

<style scoped></style>
