<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold tw-ml-5 lg:tw-ml-6">{{ $t('workCenters.title') }}</h1>
            </v-col>

            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn

                    color="primary"
                    @click="$router.push({ name: 'CreateWorkCenter' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col>
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-bg-white tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field

                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    :placeholder="$t('shared.search')"></v-text-field>
                <!-- <v-btn

                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-tune"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-virtual
                :headers="headers"
                :hover="true"
                :items="workCenters"
                @click:row="onRowClick"
                item-value="Código"></v-data-table-virtual>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { WorkCenter } from '@/types';
    import { getWorkCenters } from '@/api/workCenters';
    import { Ref, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';

    const { showLoader, hideLoader } = useLoader();
    const headers = ref([
        { title: 'ID', key: 'IDCentroTrabalho' },
        { title: 'Código', key: 'CentroTrabalho' },
        { title: 'Descrição', key: 'Descricao' },
    ]);
    const workCenters = ref([]) as Ref<WorkCenter[]>;
    const $router = useRouter();

    async function onRowClick(row: WorkCenter, o: any) {
        console.log(row);
        console.log(o);

        $router.push({ name: 'WorkCenter', params: { id: o.item.IDCentroTrabalho } });
    }

    async function init() {
        showLoader();
        console.log('init');
        try {
            workCenters.value = (await getWorkCenters(null, null, null, null)).data;
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    }

    init();
</script>

<style scoped></style>
